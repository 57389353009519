import { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';
import authService from '../services/authService';

function Register() {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [error, setError] = useState('');
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState('');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { setIsLoggedIn } = useContext(AuthContext);

  const usernameRegex = /^[a-zA-Z0-9]{1,10}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const passwordRegex = /^[a-zA-Z0-9]{6,15}$/;
  const phoneRegex = /^\d{10}$/;

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setErrors([]);
    setSuccess('');
    setLoading(true);

    let valid = true;

    if (!usernameRegex.test(username)) {
      setUsernameError('Username must be letters and numbers only and up to 10 characters long.');
      valid = false;
    } else {
      setUsernameError('');
    }

    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!passwordRegex.test(password)) {
      setPasswordError(
        'Password must be 6-15 characters long and only contain letters and numbers.',
      );
      valid = false;
    } else {
      setPasswordError('');
    }

    if (!phoneRegex.test(phone)) {
      setPhoneError('Phone number must be exactly 10 digits.');
      valid = false;
    } else {
      setPhoneError('');
    }

    if (!valid) {
      setLoading(false);
      return;
    }

    try {
      const userData = {
        email: email.toLowerCase(),
        password,
        phone: `+1${phone}`,
        username,
      };

      await authService.register(userData);

      const loginResponse = await authService.login({
        email: email.toLowerCase(),
        password,
      });
      if (loginResponse) {
        setIsLoggedIn(true);
        navigate('/shifts');
      } else {
        setSuccess('Registration successful! Please log in.');
        navigate('/login');
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        setError(err.response.data.msg);
      } else {
        setError('Registration failed. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white p-8 rounded-lg shadow-lg w-full max-w-md'>
        <div className='text-center mb-4'>
          <img
            src='/gardashifts.png'
            alt='Garda Shifts Logo'
            className='mx-auto w-48 h-48'
          />
        </div>

        <h2 className='text-2xl font-bold mb-6 text-center'>Register</h2>

        {error && (
          <div className='bg-red-100 text-red-700 p-4 rounded mb-4 text-center'>{error}</div>
        )}

        {errors.length > 0 && (
          <div className='bg-red-100 text-red-700 p-4 rounded mb-4 text-left'>
            <ul className='list-disc list-inside'>
              {errors.map((errMsg, index) => (
                <li key={index}>{errMsg}</li>
              ))}
            </ul>
          </div>
        )}

        {success && (
          <div className='bg-green-100 text-green-700 p-4 rounded mb-4 text-center'>{success}</div>
        )}

        <form
          onSubmit={handleRegisterSubmit}
          className='space-y-4'
        >
          <input
            type='text'
            placeholder='Username (max 10 chars, alphanumeric)'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className={`w-full px-4 py-2 rounded-lg border ${
              usernameError ? 'border-red-500' : 'border-gray-300'
            } focus:outline-none focus:ring-2 focus:ring-blue-400`}
            required
          />
          {usernameError && <p className='text-red-500'>{usernameError}</p>}

          <input
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            pattern='[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'
            title='Please enter a valid email address (e.g., user@example.com)'
            className={`w-full px-4 py-2 rounded-lg border ${
              emailError ? 'border-red-500' : 'border-gray-300'
            } focus:outline-none focus:ring-2 focus:ring-blue-400`}
            required
          />
          {emailError && <p className='text-red-500'>{emailError}</p>}

          <input
            type='password'
            placeholder='Password (6-15 chars, alphanumeric)'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            minLength='6'
            maxLength='15'
            title='Password must be between 6 and 15 characters long and only contain letters and numbers'
            className={`w-full px-4 py-2 rounded-lg border ${
              passwordError ? 'border-red-500' : 'border-gray-300'
            } focus:outline-none focus:ring-2 focus:ring-blue-400`}
            required
          />
          {passwordError && <p className='text-red-500'>{passwordError}</p>}

          <div className='flex'>
            <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
              +1
            </span>
            <input
              type='tel'
              placeholder='XXXXXXXXXX'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              pattern='\d{10}'
              title='Phone number must be exactly 10 digits'
              maxLength='10'
              className={`w-full px-4 py-2 rounded-r-md border ${
                phoneError ? 'border-red-500' : 'border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-blue-400`}
              required
            />
          </div>
          {phoneError && <p className='text-red-500'>{phoneError}</p>}

          <button
            type='submit'
            className={`w-full py-2 rounded-lg ${
              loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'
            } text-white`}
            disabled={loading}
          >
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>

        <div className='mt-6 text-center'>
          <p className='text-sm'>
            Already have an account?{' '}
            <Link
              to='/login'
              className='text-blue-500 hover:underline'
            >
              Login here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
