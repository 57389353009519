import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBCgtjzLGHTWLD9a0ePyHwucRoBXwHrjlU',
  appId: '1:575126790547:web:b033ca369d104939468866',
  authDomain: 'garda-shifts.firebaseapp.com',
  messagingSenderId: '575126790547',
  projectId: 'garda-shifts',
  storageBucket: 'garda-shifts.appspot.com',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const VAPID_KEY =
  'BKJKD7v8OPpXYCjWz49tzGyHnp2JQKH4Oj3EKNgHCT7pFbw8gOpD5rmMx_eelVUdu5_51449bdCqDrKezXngFH8';

const retrieveFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: VAPID_KEY });
    if (currentToken) {
      return currentToken;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export { app, messaging, retrieveFCMToken };
