import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useState, useEffect } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import { FaPlusCircle } from 'react-icons/fa';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import BottomNav from './BottomNav';
import holidayEvents from '../data/holidays';
import './MyShifts.css';
import PostShiftForm from './PostShiftForm';
import shiftService from '../services/shiftService';

dayjs.extend(isoWeek);

const localizer = dayjsLocalizer(dayjs);

function MyShifts() {
  const [shifts, setShifts] = useState([]);
  const [events, setEvents] = useState([]);
  const [isPosting, setIsPosting] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchShifts = async () => {
      try {
        const userShifts = await shiftService.getUserShifts();
        setShifts(userShifts);

        const userShiftEvents = userShifts.map((shift) => {
          const shiftDate = dayjs(shift.date);
          const startDateTime = dayjs(
            `${shiftDate.format('YYYY-MM-DD')} ${shift.start}`,
            'YYYY-MM-DD HH:mm',
          );

          let endDateTime = dayjs(
            `${shiftDate.format('YYYY-MM-DD')} ${shift.end}`,
            'YYYY-MM-DD HH:mm',
          );

          if (endDateTime.isSameOrBefore(startDateTime)) {
            endDateTime = endDateTime.add(1, 'day');
          }

          let calendarEndDateTime = endDateTime;
          if (endDateTime.date() !== startDateTime.date()) {
            calendarEndDateTime = startDateTime.endOf('day');
          }

          return {
            allDay: false,
            end: calendarEndDateTime.toDate(),
            shift,
            start: startDateTime.toDate(),
            title: `${shift.location} - ${shift.checkpoint}`,
          };
        });

        setEvents(userShiftEvents);
      } catch (error) {
        setError('Failed to load shifts. Please try again later.');
      }
    };
    fetchShifts();
  }, []);

  const handlePostShift = async (newShift) => {
    try {
      const createdShift = await shiftService.postShift(newShift);
      setShifts([...shifts, createdShift]);

      const shiftDate = dayjs(createdShift.date);
      const startDateTime = dayjs(
        `${shiftDate.format('YYYY-MM-DD')} ${createdShift.start}`,
        'YYYY-MM-DD HH:mm',
      );

      let endDateTime = dayjs(
        `${shiftDate.format('YYYY-MM-DD')} ${createdShift.end}`,
        'YYYY-MM-DD HH:mm',
      );

      if (endDateTime.isSameOrBefore(startDateTime)) {
        endDateTime = endDateTime.add(1, 'day');
      }

      const newEvent = {
        allDay: false,
        end: endDateTime.toDate(),
        shift: createdShift,
        start: startDateTime.toDate(),
        title: `${createdShift.location} - ${createdShift.checkpoint}`,
      };

      setEvents([...events, newEvent]);
      setIsPosting(false);
      setError('');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to save shift. Please try again.');
    }
  };

  const handleDeleteShift = async (shiftId) => {
    try {
      await shiftService.deleteShift(shiftId);
      setShifts(shifts.filter((shift) => shift._id !== shiftId));
      setEvents(events.filter((event) => event.shift?._id !== shiftId));
      setSelectedEvent(null);
    } catch (error) {
      setError('Failed to delete shift. Please try again.');
    }
  };

  const openPostShiftForm = () => setIsPosting(true);

  const eventPropGetter = (event) => {
    const newStyle = {
      backgroundColor: '#3b82f6',
      border: '0px',
      borderRadius: '5px',
      color: 'white',
      display: 'block',
      margin: '2px 0',
      opacity: 0.8,
    };

    if (event.shift && event.shift.isTrade) {
      newStyle.backgroundColor = '#ff9800';
    }

    return {
      style: newStyle,
    };
  };

  function CustomEvent({ event }) {
    const startTime = event.shift.start;
    const endTime = event.shift.end;
    return (
      <div style={{ lineHeight: '1em', whiteSpace: 'normal' }}>
        <div>{startTime}</div>
        <div>{endTime}</div>
      </div>
    );
  }

  function CustomDateHeader({ label, date }) {
    const holiday = holidayEvents.find((holiday) => dayjs(holiday.start).isSame(date, 'day'));

    return (
      <div className='rbc-date-cell'>
        <span>{label}</span>
        {holiday && (
          <span
            role='img'
            aria-label='Holiday'
            className='holiday-icon'
            onClick={() => setSelectedHoliday(holiday)}
          >
            🎉
          </span>
        )}
      </div>
    );
  }

  function CustomToolbar(toolbar) {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
      toolbar.onNavigate('NEXT');
    };

    const label = () => {
      const date = dayjs(toolbar.date);
      return date.format('MMMM YYYY');
    };

    return (
      <div className='rbc-toolbar mb-4'>
        <div className='flex justify-center items-center'>
          <button
            type='button'
            onClick={goToBack}
            className='bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-1 px-3 rounded'
          >
            {'<'}
          </button>
          <span className='text-lg font-bold mx-4'>{label()}</span>
          <button
            type='button'
            onClick={goToNext}
            className='bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-1 px-3 rounded'
          >
            {'>'}
          </button>
        </div>
        <div className='flex justify-center mt-2'>
          <div className='flex items-center mr-4'>
            <div className='w-4 h-4 bg-green-500 mr-2' />
            <span>Pay Day</span>
          </div>
          <div className='flex items-center'>
            <span>🎉 Holiday</span>
          </div>
        </div>
      </div>
    );
  }

  const dayPropGetter = (date) => {
    const dayStyles = {};

    const currentDate = dayjs(date).startOf('day');
    const firstPayDay = dayjs('2024-10-03').startOf('day');

    const isThursday = currentDate.day() === 4;
    const weeksSinceFirstPayDay = currentDate.diff(firstPayDay, 'week');

    if (
      currentDate.isSame(firstPayDay, 'day') ||
      (currentDate.isAfter(firstPayDay) && isThursday && weeksSinceFirstPayDay % 2 === 0)
    ) {
      dayStyles.backgroundColor = 'green';
    }

    return {
      style: dayStyles,
    };
  };

  return (
    <div className='flex flex-col h-screen bg-gray-100'>
      <div className='fixed top-0 left-0 right-0 bg-white z-50 shadow-md'>
        <div className='flex justify-between items-center p-4'>
          <h2 className='text-2xl font-bold text-gray-800'>My Shifts</h2>
          <button
            onClick={openPostShiftForm}
            className='flex items-center bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition'
          >
            <FaPlusCircle className='mr-2' />
            Post Shift
          </button>
        </div>
      </div>

      <div className='flex-grow overflow-y-auto p-4 pt-20 mb-20'>
        {error && (
          <div className='mb-4'>
            <p className='text-red-500 text-center'>{error}</p>
          </div>
        )}
        {events.length === 0 ? (
          <p className='text-center text-gray-500 mt-10'>You have no posted shifts.</p>
        ) : (
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor='start'
            endAccessor='end'
            style={{ height: 'calc(100vh - 200px)' }}
            selectable
            onSelectEvent={(event) => setSelectedEvent(event)}
            popup={false}
            views={['month']}
            components={{
              dateHeader: CustomDateHeader,
              event: CustomEvent,
              toolbar: CustomToolbar,
            }}
            eventPropGetter={eventPropGetter}
            dayPropGetter={dayPropGetter}
            longPressThreshold={10}
          />
        )}
      </div>

      {isPosting && (
        <PostShiftForm
          onSubmit={handlePostShift}
          onCancel={() => setIsPosting(false)}
        />
      )}

      {selectedEvent && (
        <EventDetailsModal
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onDelete={() => {
            if (selectedEvent.shift) {
              handleDeleteShift(selectedEvent.shift._id);
            } else {
              setSelectedEvent(null);
            }
          }}
        />
      )}

      {selectedHoliday && (
        <HolidayDetailsModal
          holiday={selectedHoliday}
          onClose={() => setSelectedHoliday(null)}
        />
      )}

      <BottomNav />
    </div>
  );
}

function EventDetailsModal({ event, onClose, onDelete }) {
  const { shift } = event;
  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative'>
        <h3 className='text-xl font-bold mb-4 text-center'>Shift Details</h3>
        <p className='text-sm text-center mb-4'>
          <strong>
            {shift.location} - {shift.checkpoint}
          </strong>
        </p>
        <p className='text-sm text-center mb-6'>
          {dayjs(shift.date).format('dddd')}, {dayjs(shift.date).format('MMM D')} <br />
          {shift.start} - {shift.end}
        </p>
        <div className='flex justify-between'>
          <button
            onClick={onDelete}
            className='bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition'
          >
            Delete
          </button>
          <button
            onClick={onClose}
            className='bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition'
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

function HolidayDetailsModal({ holiday, onClose }) {
  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative'>
        <h3 className='text-xl font-bold mb-4 text-center'>{holiday.title}</h3>
        <p className='text-sm text-center mb-4'>
          {dayjs(holiday.start).format('dddd, MMM D, YYYY')}
        </p>
        <button
          onClick={onClose}
          className='w-full bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400'
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default MyShifts;
