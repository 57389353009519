function News() {
  return (
    <div className='relative'>
      <iframe
        src='https://news.gardashifts.com'
        title='News'
        className='w-full'
        style={{ height: 'calc(100vh - 64px)' }}
      />
    </div>
  );
}

export default News;
