import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import authService from '../services/authService';
import { messaging, retrieveFCMToken } from '../firebase';
import { onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';
import shareIcon from '../assets/share-icon.png';

const Settings = () => {
  const defaultNotificationPreferences = {
    date: '',
    day: '',
    startTime: '',
    checkpoint: '',
    location: '',
  };

  const [user, setUser] = useState({
    username: '',
    email: '',
    phone: '',
    notificationPreferences: { ...defaultNotificationPreferences },
  });
  
  const [profileChanged, setProfileChanged] = useState(false);
  const [notificationPreferencesChanged, setNotificationPreferencesChanged] = useState(false);
  
  const { isLoggedIn } = useContext(AuthContext);

  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [showAddToHomeScreenInstructions, setShowAddToHomeScreenInstructions] = useState(false);
  const [deviceType, setDeviceType] = useState(null);

  const updateFCMToken = async (token) => {
    try {
      await authService.updateUserFCMToken(token);
    } catch (err) {
      toast.error('Failed to update FCM token on the server.');
    }
  };

  const detectDeviceType = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
      return 'iOS';
    }

    if (/android/i.test(ua)) {
      return 'Android';
    }

    return 'Other';
  };

  useEffect(() => {
    if (isLoggedIn) {
      const fetchUserData = async () => {
        try {
          const userData = await authService.getUserProfile();
          setUser({
            ...userData,
            notificationPreferences: userData.notificationPreferences || { ...defaultNotificationPreferences },
          });
          setNotificationsEnabled(!!userData.fcmToken);
        } catch (err) {
          console.error('Failed to fetch user data', err);
          toast.error('Failed to fetch user data.');
        }
      };
      fetchUserData();
    }
  }, [isLoggedIn]);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('notificationPreferences.')) {
      const key = name.split('.')[1];
      setUser((prevUser) => ({
        ...prevUser,
        notificationPreferences: {
          ...prevUser.notificationPreferences,
          [key]: value,
        },
      }));
      setNotificationPreferencesChanged(true);
    } else {
      setUser((prevUser) => ({ ...prevUser, [name]: value }));
      setProfileChanged(true);
    }
  };

  const updateProfileHandler = async () => {
    try {
      await authService.updateUserProfile(user);
      toast.success('Profile updated successfully!');
      setProfileChanged(false);
    } catch (err) {
      console.error('Failed to update profile', err);
      toast.error('Failed to update profile. Please try again.');
    }
  };

  const resetNotificationPreferences = async () => {
    const confirmReset = window.confirm(
      'Are you sure you want to reset your notification preferences to default values?'
    );
    if (!confirmReset) return;

    const updatedUser = {
      ...user,
      notificationPreferences: { ...defaultNotificationPreferences },
    };

    try {
      await authService.updateUserProfile(updatedUser);
      toast.success('Notification preferences have been reset to default.');
      setUser(updatedUser);
      setNotificationPreferencesChanged(false);
    } catch (err) {
      console.error('Failed to reset notification preferences:', err);
      toast.error('Failed to reset notification preferences. Please try again.');
    }
  };

  const requestNotificationPermission = async () => {
    try {
      const detectedDevice = detectDeviceType();
      setDeviceType(detectedDevice);

      if (!('PushManager' in window)) {
        if (detectedDevice === 'iOS' || detectedDevice === 'Android') {
          setShowAddToHomeScreenInstructions(true);
        } else {
          toast.error('Push messaging is not supported in this browser.');
        }
        return;
      }

      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        try {
          const currentToken = await retrieveFCMToken();
          if (currentToken) {
            await updateFCMToken(currentToken);
            setNotificationsEnabled(true);
            toast.success('Notifications enabled!');
          } else {
            toast.warning('Notifications enabled, but no token received.');
          }
        } catch (err) {
          console.error('Failed to retrieve FCM token:', err);
          toast.error('Failed to enable notifications.');
          if (detectedDevice === 'iOS' || detectedDevice === 'Android') {
            setShowAddToHomeScreenInstructions(true);
          }
        }
      } else {
        toast.error('Notifications are disabled.');
        if (detectedDevice === 'iOS' || detectedDevice === 'Android') {
          setShowAddToHomeScreenInstructions(true);
        }
      }
    } catch (err) {
      console.error('Error requesting notification permission:', err);
      toast.error('Failed to enable notifications.');
    }
  };

  useEffect(() => {
    if (notificationsEnabled) {
      const unsubscribe = onMessage(messaging, (payload) => {
        const notificationTitle = payload.notification.title || 'New Notification';
        const notificationBody = payload.notification.body || 'You have a new message.';
        toast.info(`${notificationTitle}: ${notificationBody}`);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [notificationsEnabled]);

  if (!isLoggedIn) {
    return <p>Please log in to view this page.</p>;
  }

  return (
    <div className="p-4 mb-20">
      <h2 className="text-2xl font-bold mb-4">Settings</h2>
      
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Profile</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <input
            type="text"
            name="username"
            value={user.username}
            onChange={handleProfileChange}
            placeholder="Username"
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          />
          <input
            type="email"
            name="email"
            value={user.email}
            onChange={handleProfileChange}
            placeholder="Email"
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          />
          <input
            type="tel"
            name="phone"
            value={user.phone}
            onChange={handleProfileChange}
            placeholder="Phone Number (must start with +1)"
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          />
        </div>
        {profileChanged && (
          <button
            onClick={updateProfileHandler}
            className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
          >
            Update
          </button>
        )}
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Notification Preferences</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <input
            type="date"
            name="notificationPreferences.date"
            value={user.notificationPreferences?.date || ''}
            onChange={handleProfileChange}
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          />
          <select
            name="notificationPreferences.day"
            value={user.notificationPreferences?.day || ''}
            onChange={handleProfileChange}
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          >
            <option value="">Select Day</option>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
              (day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              )
            )}
          </select>
          <input
            type="time"
            name="notificationPreferences.startTime"
            value={user.notificationPreferences?.startTime || ''}
            onChange={handleProfileChange}
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          />
          <select
            name="notificationPreferences.location"
            value={user.notificationPreferences?.location || ''}
            onChange={handleProfileChange}
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          >
            <option value="">Select Location</option>
            <option value="T1">T1</option>
            <option value="T3">T3</option>
            <option value="Island">Island</option>
            <option value="Hamilton">Hamilton</option>
          </select>
          <select
            name="notificationPreferences.checkpoint"
            value={user.notificationPreferences?.checkpoint || ''}
            onChange={handleProfileChange}
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          >
            <option value="">Select Checkpoint</option>
            <option value="International">International</option>
            <option value="Domestic">Domestic</option>
            <option value="TB">TB</option>
            <option value="ITD/ITPC">ITD/ITPC</option>
            <option value="NPS">NPS</option>
            <option value="NPS-V">NPS-V</option>
            <option value="HBS">HBS</option>
            <option value="RSR">RSR</option>
          </select>
        </div>
        {notificationPreferencesChanged && (
          <div className="flex mt-4">
            <button
              onClick={updateProfileHandler}
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
            >
              Update
            </button>
            <button
              onClick={resetNotificationPreferences}
              className="ml-4 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
            >
              Reset
            </button>
          </div>
        )}
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Notifications</h3>
        <button
          onClick={requestNotificationPermission}
          className={`text-white py-2 px-4 rounded-lg hover:bg-blue-600 ${
            notificationsEnabled ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500'
          }`}
        >
          {notificationsEnabled ? 'Notifications Enabled' : 'Enable Notifications'}
        </button>
      </div>

      {showAddToHomeScreenInstructions && deviceType && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Enable Notifications</h3>
            {deviceType === 'iOS' && (
              <div>
                <p className="mb-4">
                  To receive notifications, please add this app to your home screen:
                </p>
                <ol className="list-decimal list-inside mb-4">
                  <li className="mb-2">
                    Open the Share menu by tapping the{' '}
                    <img
                      src={shareIcon}
                      alt="Share Icon"
                      className="inline-block w-5 h-5 mx-1"
                    />
                    icon.
                  </li>
                  <li>
                    Scroll down and select <span className="font-bold">Add to Home Screen</span>.
                  </li>
                  <li>
                    Tap <span className="font-bold">Add</span> in the top-right corner.
                  </li>
                </ol>
                <button
                  onClick={() => setShowAddToHomeScreenInstructions(false)}
                  className="mt-2 bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                >
                  Close
                </button>
              </div>
            )}
            {deviceType === 'Android' && (
              <div>
                <p className="mb-4">
                  To receive notifications, please add this app to your home screen:
                </p>
                <ol className="list-decimal list-inside mb-4">
                  <li>
                    Open the Chrome menu by tapping the <span className="font-bold">three dots</span> in the top-right corner.
                  </li>
                  <li>
                    Select <span className="font-bold">Add to Home screen</span>.
                  </li>
                  <li>
                    Tap <span className="font-bold">Add</span>.
                  </li>
                </ol>
                <button
                  onClick={() => setShowAddToHomeScreenInstructions(false)}
                  className="mt-2 bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                >
                  Close
                </button>
              </div>
            )}
            {deviceType === 'Other' && (
              <div>
                <p className="mb-4">
                  Push notifications are not supported on your device. Please use a supported browser to enable notifications.
                </p>
                <button
                  onClick={() => setShowAddToHomeScreenInstructions(false)}
                  className="mt-2 bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
