import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import authService from '../services/authService';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const passwordRegex = /^[a-zA-Z0-9]{6,15}$/;

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    let valid = true;

    if (!passwordRegex.test(password)) {
      setPasswordError('Password must be 6-15 characters long and alphanumeric.');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      valid = false;
    } else {
      setConfirmPasswordError('');
    }

    if (!valid) {
      return;
    }

    try {
      const response = await authService.resetPassword({ password, token });
      setMessage(response.msg);
      setTimeout(() => navigate('/login'), 2000);
    } catch (err) {
      setError(err.response?.data?.msg || 'Error resetting password. Please try again.');
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white p-8 rounded-lg shadow-lg w-full max-w-md'>
        <h2 className='text-2xl font-bold mb-6 text-center'>Reset Password</h2>
        {message && (
          <div className='bg-green-100 text-green-700 p-4 rounded mb-4 text-center'>{message}</div>
        )}
        {error && (
          <div className='bg-red-100 text-red-700 p-4 rounded mb-4 text-center'>{error}</div>
        )}
        <form
          onSubmit={handleResetPassword}
          className='space-y-4'
        >
          <input
            type='password'
            placeholder='New Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            minLength='6'
            maxLength='15'
            className='w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400'
            required
          />
          {passwordError && <p className='text-red-500'>{passwordError}</p>}
          <input
            type='password'
            placeholder='Confirm New Password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            minLength='6'
            maxLength='15'
            className='w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400'
            required
          />
          {confirmPasswordError && <p className='text-red-500'>{confirmPasswordError}</p>}
          <button
            type='submit'
            className='w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600'
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
