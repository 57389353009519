import { useState, useEffect } from 'react';
import {
  FaWhatsapp,
  FaExchangeAlt,
  FaFire,
  FaRedoAlt,
  FaCalendarAlt,
  FaClock,
  FaDollarSign,
  FaSyncAlt,
} from 'react-icons/fa';

import BottomNav from './BottomNav';
import shiftService from '../services/shiftService';

const formatDate = (dateInput) => {
  const date = new Date(dateInput);
  if (isNaN(date)) return 'Invalid Date';

  const options = { day: 'numeric', month: 'short' };
  return date.toLocaleDateString('en-US', options);
};

const getDayName = (dateInput) => {
  const date = new Date(dateInput);
  if (isNaN(date)) return 'Invalid Date';

  return date.toLocaleDateString('en-US', { weekday: 'long' });
};

const getDateString = (dateInput) => {
  const date = new Date(dateInput);
  if (isNaN(date)) return 'Invalid Date';

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const calculateDuration = (start, end) => {
  const [startHour, startMinute] = start.split(':').map(Number);
  const [endHour, endMinute] = end.split(':').map(Number);

  const startTotalMinutes = startHour * 60 + startMinute;
  let endTotalMinutes = endHour * 60 + endMinute;

  if (endTotalMinutes <= startTotalMinutes) {
    endTotalMinutes += 24 * 60;
  }

  const totalMinutes = endTotalMinutes - startTotalMinutes;
  const totalHours = totalMinutes / 60;

  return totalHours % 1 === 0 ? `${totalHours}h` : `${totalHours.toFixed(1)}h`;
};

const isLastMinute = (shiftDateInput, shiftStart) => {
  const shiftDateTimeString = `${getDateString(shiftDateInput)}T${shiftStart}`;
  const shiftTime = new Date(shiftDateTimeString);
  const currentTime = new Date();

  const timeDiff = (shiftTime - currentTime) / (1000 * 60);
  return timeDiff <= 120 && timeDiff > 0;
};

function Shifts() {
  const [selectedShift, setSelectedShift] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [filteredShifts, setFilteredShifts] = useState([]);
  const [filters, setFilters] = useState({
    date: '',
    day: '',
    duration: '',
    hasIncentive: false,
    isLastMinute: false,
    isRecurring: false,
    isTrade: false,
    location: '',
    startTime: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const fetchShifts = async () => {
    setLoading(true);
    try {
      const shiftsData = await shiftService.getShifts();
      const processedShifts = shiftsData.map((shift) => ({
        ...shift,
        date: shift.date.$date || shift.date,
        endRecurringDate: shift.endRecurringDate?.$date || shift.endRecurringDate,
        incentive: Number(shift.incentive) || 0,
        isTrade: !!shift.isTrade,
        recurring: !!shift.recurring,
        user: {
          ...shift.user,
          phone: shift.user.phone,
        },
      }));

      setShifts(processedShifts);
      setFilteredShifts(processedShifts);
      setError('');
    } catch {
      setError('Failed to fetch shifts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShifts();
  }, []);

  const openModal = (shift) => setSelectedShift(shift);
  const closeModal = () => setSelectedShift(null);
  const toggleFilterModal = () => setShowFilterModal(!showFilterModal);

  const resetFilters = () => {
    setFilters({
      date: '',
      day: '',
      duration: '',
      hasIncentive: false,
      isLastMinute: false,
      isRecurring: false,
      isTrade: false,
      location: '',
      startTime: '',
    });
  };

  const handleFilter = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  useEffect(() => {
    const filtered = shifts.filter((shift) => {
      const shiftDuration = calculateDuration(shift.start, shift.end);
      const shiftDay = getDayName(shift.date);
      const isLastMinuteShift = isLastMinute(shift.date, shift.start);
      const hasIncentive = shift.incentive > 0;

      return (
        (!filters.location ||
          shift.location.toLowerCase().includes(filters.location.toLowerCase())) &&
        (!filters.day || shiftDay === filters.day) &&
        (!filters.date || getDateString(shift.date) === filters.date) &&
        (!filters.startTime || shift.start === filters.startTime) &&
        (!filters.duration || shiftDuration === filters.duration) &&
        (!filters.isTrade || shift.isTrade) &&
        (!filters.isLastMinute || isLastMinuteShift) &&
        (!filters.isRecurring || shift.recurring) &&
        (!filters.hasIncentive || hasIncentive)
      );
    });

    filtered.sort(
      (a, b) =>
        new Date(`${getDateString(a.date)}T${a.start}`) -
        new Date(`${getDateString(b.date)}T${b.start}`),
    );
    setFilteredShifts(filtered);
  }, [filters, shifts]);

  const handleTimeChange = (e) => {
    const [hours, minutes] = e.target.value.split(':');
    if (minutes !== '00' && minutes !== '30') {
      e.target.value = `${hours}:${minutes < 30 ? '00' : '30'}`;
    }
    handleFilter(e);
  };

  const generateWhatsAppLink = (shift) => {
    const message = `Hi, is your ${shift.location} - ${shift.checkpoint} on ${getDayName(
      shift.date,
    )}, ${formatDate(shift.date)} from ${shift.start} - ${shift.end} shift still available?`;
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/${shift.user.phone}?text=${encodedMessage}`;
  };

  return (
    <div className='flex flex-col h-screen'>
      <div className='fixed top-0 left-0 right-0 bg-white z-50 shadow-md'>
        <div className='flex justify-between items-center p-4 flex-nowrap'>
          <h2 className='text-2xl font-bold truncate min-w-0 flex-1'>Shifts</h2>
          <div className='flex space-x-2 flex-shrink-0'>
            <button
              onClick={fetchShifts}
              className={`bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 flex items-center ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={loading}
              title='Refresh Shifts'
            >
              <FaSyncAlt className='mr-2' />
              {loading ? 'Refreshing...' : 'Refresh List'}
            </button>
            <button
              onClick={toggleFilterModal}
              className='bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 flex items-center'
            >
              Filter Shifts
            </button>
          </div>
        </div>
        <div className='flex justify-center space-x-6 p-4'>
          <div className='flex items-center space-x-2'>
            <FaExchangeAlt className='text-blue-500' />
            <span>Trade</span>
          </div>
          <div className='flex items-center space-x-2'>
            <FaFire className='text-red-500' />
            <span>Soon</span>
          </div>
          <div className='flex items-center space-x-2'>
            <FaRedoAlt className='text-green-500' />
            <span>Recurring</span>
          </div>
          <div className='flex items-center space-x-2'>
            <FaDollarSign className='text-green-500' />
            <span>Incentive</span>
          </div>
        </div>
      </div>

      <div className='flex-1 mt-32 mb-20 overflow-y-auto p-4'>
        {error && <p className='text-red-500'>{error}</p>}
        <div className='space-y-4'>
          {filteredShifts.map((shift) => {
            const isLastMinuteShift = isLastMinute(shift.date, shift.start);
            return (
              <div
                key={shift._id}
                className='bg-white p-4 rounded-lg shadow-md flex flex-col cursor-pointer hover:bg-gray-50 transition'
                onClick={() => openModal(shift)}
              >
                <div className='flex items-center'>
                  <div className='flex flex-col flex-shrink-0 w-1/4'>
                    <p className='font-bold'>{shift.location}</p>
                    <p className='text-sm text-gray-500'>{shift.checkpoint}</p>
                  </div>
                  <div className='flex flex-col items-center flex-grow'>
                    <div className='flex items-center space-x-2 mb-1'>
                      {shift.isTrade && (
                        <FaExchangeAlt className='text-blue-500' title='Trade Shift' />
                      )}
                      {isLastMinuteShift && (
                        <FaFire className='text-red-500' title='Last Minute Shift' />
                      )}
                      {shift.recurring && (
                        <FaRedoAlt className='text-green-500' title='Recurring Shift' />
                      )}
                      {Number(shift.incentive) > 0 && (
                        <FaDollarSign className='text-green-500' title='Incentive Available' />
                      )}
                    </div>
                    <p className='text-sm text-gray-500 text-center'>{`${shift.start} - ${shift.end}`}</p>
                  </div>
                  <div className='flex flex-col items-end flex-shrink-0 w-1/4'>
                    <p className='text-sm'>{formatDate(shift.date)}</p>
                    <p className='text-sm text-gray-500'>{getDayName(shift.date)}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {showFilterModal && (
        <div
          className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'
          style={{ zIndex: 60 }}
        >
          <div className='bg-white p-6 rounded-lg shadow-lg max-w-lg w-full'>
            <h3 className='text-xl font-bold mb-4 text-center'>Filter Shifts</h3>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
              <div className='relative'>
                <FaCalendarAlt className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400' />
                <input
                  type='date'
                  name='date'
                  value={filters.date}
                  className='w-full pl-10 py-2 rounded-lg border border-gray-300'
                  onChange={handleFilter}
                />
              </div>
              <div className='relative'>
                <select
                  name='day'
                  value={filters.day}
                  className='w-full px-4 py-2 rounded-lg border border-gray-300 appearance-none'
                  onChange={handleFilter}
                >
                  <option value=''>Day</option>
                  {[
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                  ].map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
              <div className='relative'>
                <FaClock className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400' />
                <input
                  type='time'
                  name='startTime'
                  value={filters.startTime}
                  className='w-full pl-10 py-2 rounded-lg border border-gray-300'
                  step='1800'
                  onChange={handleTimeChange}
                />
              </div>
              <div className='relative'>
                <select
                  name='duration'
                  value={filters.duration}
                  className='w-full px-4 py-2 rounded-lg border border-gray-300 appearance-none'
                  onChange={handleFilter}
                >
                  <option value=''>Duration</option>
                  {['4h', '8h', '10h', '12h', '14h', '16h'].map((duration) => (
                    <option key={duration} value={duration}>
                      {duration}
                    </option>
                  ))}
                </select>
              </div>
              <div className='flex justify-between items-center col-span-2'>
                <label className='flex items-center space-x-2'>
                  <input
                    type='checkbox'
                    name='isTrade'
                    checked={filters.isTrade}
                    onChange={handleFilter}
                    className='form-checkbox h-4 w-4 text-blue-600'
                  />
                  <span>Trade Shift</span>
                </label>
                <label className='flex items-center space-x-2'>
                  <input
                    type='checkbox'
                    name='isLastMinute'
                    checked={filters.isLastMinute}
                    onChange={handleFilter}
                    className='form-checkbox h-4 w-4 text-red-600'
                  />
                  <span>Soon</span>
                </label>
                <label className='flex items-center space-x-2'>
                  <input
                    type='checkbox'
                    name='isRecurring'
                    checked={filters.isRecurring}
                    onChange={handleFilter}
                    className='form-checkbox h-4 w-4 text-green-600'
                  />
                  <span>Recurring</span>
                </label>
                <label className='flex items-center space-x-2'>
                  <input
                    type='checkbox'
                    name='hasIncentive'
                    checked={filters.hasIncentive}
                    onChange={handleFilter}
                    className='form-checkbox h-4 w-4 text-yellow-600'
                  />
                  <span>Incentive</span>
                </label>
              </div>
            </div>
            <div className='flex justify-between mt-6'>
              <button
                onClick={resetFilters}
                className='bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600'
              >
                Reset Filters
              </button>
              <button
                onClick={toggleFilterModal}
                className='bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400'
              >
                Close Filter
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedShift && (
        <div
          className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'
          style={{ zIndex: 60 }}
        >
          <div className='bg-white p-6 rounded-lg shadow-lg max-w-sm w-full'>
            <h3 className='text-xl font-bold mb-4 text-center'>
              {selectedShift.location} - {selectedShift.checkpoint}
            </h3>
            <div className='flex justify-between items-center mb-2'>
              <p className='text-sm'>
                <strong>Day:</strong> {getDayName(selectedShift.date)}
              </p>
              <p className='text-sm'>
                <strong>Date:</strong> {formatDate(selectedShift.date)}
              </p>
            </div>
            <div className='flex justify-between items-center mb-2'>
              <p className='text-sm'>
                <strong>Start:</strong> {selectedShift.start}
              </p>
              <p className='text-sm'>
                <strong>End:</strong> {selectedShift.end}
              </p>
              {Number(selectedShift.incentive) <= 0 && (
                <p className='text-sm'>
                  <strong>Duration:</strong>{' '}
                  {calculateDuration(selectedShift.start, selectedShift.end)}
                </p>
              )}
            </div>
            {Number(selectedShift.incentive) > 0 && (
              <div className='flex justify-between items-center mb-2'>
                <p className='text-sm'>
                  <strong>Duration:</strong>{' '}
                  {calculateDuration(selectedShift.start, selectedShift.end)}
                </p>
                <p className='text-sm'>
                  <strong>Incentive:</strong> ${selectedShift.incentive}
                </p>
              </div>
            )}
            {selectedShift.recurring && selectedShift.endRecurringDate && (
              <p className='text-sm mb-4'>
                <strong>Recurring:</strong> Every {getDayName(selectedShift.date)} from{' '}
                {formatDate(selectedShift.date)} to {formatDate(selectedShift.endRecurringDate)}
              </p>
            )}
            {selectedShift.note && (
              <p className='text-sm mb-4'>
                <strong>Note:</strong> {selectedShift.note}
              </p>
            )}
            <a
              href={generateWhatsAppLink(selectedShift)}
              target='_blank'
              rel='noopener noreferrer'
              className='flex items-center justify-center bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 mb-4'
            >
              <FaWhatsapp className='mr-2' />
              Contact via WhatsApp
            </a>
            <button
              onClick={closeModal}
              className='w-full bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400'
            >
              Close
            </button>
          </div>
        </div>
      )}

      <BottomNav />
    </div>
  );
}

export default Shifts;
