const holidayEvents = [
  // Holidays for 2024
  {
    allDay: true,
    end: new Date(2024, 0, 1),
    holiday: true,
    start: new Date(2024, 0, 1),
    title: "New Year's Day",
  },
  {
    allDay: true,
    end: new Date(2024, 2, 29),
    holiday: true,
    start: new Date(2024, 2, 29),
    title: 'Good Friday',
  },
  {
    allDay: true,
    end: new Date(2024, 3, 1),
    holiday: true,
    start: new Date(2024, 3, 1),
    title: 'Easter Monday',
  },
  {
    allDay: true,
    end: new Date(2024, 4, 20),
    holiday: true,
    start: new Date(2024, 4, 20),
    title: 'Victoria Day',
  },
  {
    allDay: true,
    end: new Date(2024, 6, 1),
    holiday: true,
    start: new Date(2024, 6, 1),
    title: 'Canada Day',
  },
  {
    allDay: true,
    end: new Date(2024, 7, 5),
    holiday: true,
    start: new Date(2024, 7, 5),
    title: 'Civic Holiday',
  },
  {
    allDay: true,
    end: new Date(2024, 8, 2),
    holiday: true,
    start: new Date(2024, 8, 2),
    title: 'Labour Day',
  },
  {
    allDay: true,
    end: new Date(2024, 8, 30),
    holiday: true,
    start: new Date(2024, 8, 30),
    title: 'National Day for Truth and Reconciliation',
  },
  {
    allDay: true,
    end: new Date(2024, 9, 14),
    holiday: true,
    start: new Date(2024, 9, 14),
    title: 'Thanksgiving',
  },
  {
    allDay: true,
    end: new Date(2024, 10, 11),
    holiday: true,
    start: new Date(2024, 10, 11),
    title: 'Remembrance Day',
  },
  {
    allDay: true,
    end: new Date(2024, 11, 25),
    holiday: true,
    start: new Date(2024, 11, 25),
    title: 'Christmas Day',
  },
  {
    allDay: true,
    end: new Date(2024, 11, 26),
    holiday: true,
    start: new Date(2024, 11, 26),
    title: 'Boxing Day',
  },
  // Holidays for 2025
  {
    allDay: true,
    end: new Date(2025, 0, 1),
    holiday: true,
    start: new Date(2025, 0, 1),
    title: "New Year's Day",
  },
  {
    allDay: true,
    end: new Date(2025, 3, 18),
    holiday: true,
    start: new Date(2025, 3, 18),
    title: 'Good Friday',
  },
  {
    allDay: true,
    end: new Date(2025, 3, 21),
    holiday: true,
    start: new Date(2025, 3, 21),
    title: 'Easter Monday',
  },
  {
    allDay: true,
    end: new Date(2025, 4, 19),
    holiday: true,
    start: new Date(2025, 4, 19),
    title: 'Victoria Day',
  },
  {
    allDay: true,
    end: new Date(2025, 6, 1),
    holiday: true,
    start: new Date(2025, 6, 1),
    title: 'Canada Day',
  },
  {
    allDay: true,
    end: new Date(2025, 7, 4),
    holiday: true,
    start: new Date(2025, 7, 4),
    title: 'Civic Holiday',
  },
  {
    allDay: true,
    end: new Date(2025, 8, 1),
    holiday: true,
    start: new Date(2025, 8, 1),
    title: 'Labour Day',
  },
  {
    allDay: true,
    end: new Date(2025, 8, 30),
    holiday: true,
    start: new Date(2025, 8, 30),
    title: 'National Day for Truth and Reconciliation',
  },
  {
    allDay: true,
    end: new Date(2025, 9, 13),
    holiday: true,
    start: new Date(2025, 9, 13),
    title: 'Thanksgiving',
  },
  {
    allDay: true,
    end: new Date(2025, 10, 11),
    holiday: true,
    start: new Date(2025, 10, 11),
    title: 'Remembrance Day',
  },
  {
    allDay: true,
    end: new Date(2025, 11, 25),
    holiday: true,
    start: new Date(2025, 11, 25),
    title: 'Christmas Day',
  },
  {
    allDay: true,
    end: new Date(2025, 11, 26),
    holiday: true,
    start: new Date(2025, 11, 26),
    title: 'Boxing Day',
  },
];

export default holidayEvents;
