import { createContext, useState, useEffect } from 'react';
import authService from '../services/authService';

export const AuthContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  setUser: () => {},
  user: null,
});

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [user, setUser] = useState(null);

  useEffect(() => {
    const handleLogout = () => {
      setIsLoggedIn(false);
      setUser(null);
    };

    const handleLogin = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
        try {
          const userData = await authService.getUserProfile();
          setUser(userData);
        } catch (error) {
          setIsLoggedIn(false);
          setUser(null);
        }
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
    };

    window.addEventListener('logout', handleLogout);
    window.addEventListener('login', handleLogin);

    if (isLoggedIn) {
      handleLogin();
    }

    return () => {
      window.removeEventListener('logout', handleLogout);
      window.removeEventListener('login', handleLogin);
    };
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, setUser, user }}>
      {children}
    </AuthContext.Provider>
  );
}
