import { useContext } from 'react';
import { FaHome, FaCalendarCheck, FaCog, FaSignOutAlt, FaNewspaper } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';
import authService from '../services/authService';

function BottomNav() {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useContext(AuthContext);

  const handleLogout = () => {
    authService.logout();
    setIsLoggedIn(false);
    navigate('/login');
  };

  return (
    <nav className='fixed bottom-0 left-0 right-0 bg-white z-50 shadow-lg border-t-2 border-gray-200 h-16'>
      <ul className='flex justify-around items-center h-full'>
        <li>
          <Link
            to='/shifts'
            className='flex flex-col items-center text-gray-700 hover:text-blue-500'
          >
            <FaHome className='text-2xl' />
            <span className='text-sm'>Shifts</span>
          </Link>
        </li>
        <li>
          <Link
            to='/my-shifts'
            className='flex flex-col items-center text-gray-700 hover:text-blue-500'
          >
            <FaCalendarCheck className='text-2xl' />
            <span className='text-sm'>My Shifts</span>
          </Link>
        </li>
        <li>
          <Link
            to='/news'
            className='flex flex-col items-center text-gray-700 hover:text-blue-500'
          >
            <FaNewspaper className='text-2xl' />
            <span className='text-sm'>News</span>
          </Link>
        </li>
        <li>
          <Link
            to='/settings'
            className='flex flex-col items-center text-gray-700 hover:text-blue-500'
          >
            <FaCog className='text-2xl' />
            <span className='text-sm'>Settings</span>
          </Link>
        </li>
        <li>
          <button
            onClick={handleLogout}
            className='flex flex-col items-center text-gray-700 hover:text-red-500'
          >
            <FaSignOutAlt className='text-2xl' />
            <span className='text-sm'>Logout</span>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default BottomNav;
