import apiClient from './apiClient';

const API_URL = '/shifts';

const getShifts = async (filters = {}) => {
  const response = await apiClient.get(API_URL, { params: filters });
  return response.data;
};

const getUserShifts = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/user`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const postShift = async (shiftData) => {
  try {
    const response = await apiClient.post(API_URL, shiftData);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const deleteShift = async (shiftId) => {
  try {
    const response = await apiClient.delete(`${API_URL}/${shiftId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  deleteShift,
  getShifts,
  getUserShifts,
  postShift,
};
