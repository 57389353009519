import { useState } from 'react';

import authService from '../services/authService';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    setEmailError('');

    try {
      const response = await authService.forgotPassword({ email });
      setMessage(response.msg);
    } catch (err) {
      setError(err.response?.data?.msg || 'Error sending reset link. Please try again.');
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white p-8 rounded-lg shadow-lg w-full max-w-md'>
        <h2 className='text-2xl font-bold mb-6 text-center'>Forgot Password</h2>
        {message && (
          <div className='bg-green-100 text-green-700 p-4 rounded mb-4 text-center'>{message}</div>
        )}
        {error && (
          <div className='bg-red-100 text-red-700 p-4 rounded mb-4 text-center'>{error}</div>
        )}
        <form
          onSubmit={handleForgotPassword}
          className='space-y-4'
        >
          <input
            type='email'
            placeholder='Enter your registered email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            pattern='[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'
            title='Please enter a valid email address (e.g., user@example.com)'
            className='w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400'
            required
          />
          {emailError && <p className='text-red-500'>{emailError}</p>}
          <button
            type='submit'
            className='w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600'
          >
            Send Reset Link
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
