import dayjs from 'dayjs';
import { useState } from 'react';

function PostShiftForm({ onSubmit, onCancel }) {
  const [formData, setFormData] = useState({
    checkpoint: '',
    date: '',
    endHour: '',
    endMinute: '',
    endRecurringDate: '',
    incentive: '',
    isTrade: false,
    location: '',
    note: '',
    recurring: false,
    startHour: '',
    startMinute: '',
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const start = `${formData.startHour}:${formData.startMinute}`;
    const end = `${formData.endHour}:${formData.endMinute}`;

    const shiftStartDateTime = dayjs(`${formData.date} ${start}`, 'YYYY-MM-DD HH:mm');
    let shiftEndDateTime = dayjs(`${formData.date} ${end}`, 'YYYY-MM-DD HH:mm');

    if (shiftEndDateTime.isSameOrBefore(shiftStartDateTime)) {
      shiftEndDateTime = shiftEndDateTime.add(1, 'day');
    }

    const newErrors = {};

    if (!shiftStartDateTime.isValid()) {
      newErrors.start = 'Invalid start time.';
    }

    if (!shiftEndDateTime.isValid()) {
      newErrors.end = 'Invalid end time.';
    }

    if (shiftStartDateTime.isAfter(shiftEndDateTime)) {
      newErrors.end = 'End time must be after start time.';
    }

    if (formData.recurring && formData.endRecurringDate) {
      const endRecurringDateTime = dayjs(formData.endRecurringDate, 'YYYY-MM-DD');
      if (!endRecurringDateTime.isValid()) {
        newErrors.endRecurringDate = 'Invalid end recurring date.';
      } else if (endRecurringDateTime.isBefore(shiftStartDateTime, 'day')) {
        newErrors.endRecurringDate = 'End recurring date must be on or after the shift start date.';
      }
    }

    const validLocations = ['T1', 'T3', 'Island', 'Hamilton'];
    const validCheckpoints = [
      'International',
      'Domestic',
      'TB',
      'ITD/ITPC',
      'NPS',
      'NPS-V',
      'HBS',
      'RSR',
    ];

    if (!validLocations.includes(formData.location)) {
      newErrors.location = 'Invalid location selected.';
    }

    if (!validCheckpoints.includes(formData.checkpoint)) {
      newErrors.checkpoint = 'Invalid checkpoint selected.';
    }

    const incentiveValue = formData.incentive ? parseInt(formData.incentive, 10) : 0;
    if (
      formData.incentive &&
      (isNaN(incentiveValue) || incentiveValue < 0 || incentiveValue > 999)
    ) {
      newErrors.incentive = 'Incentive must be a number between 0 and 999.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    onSubmit({
      ...formData,
      end,
      incentive: incentiveValue,
      start,
    });
  };

  const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
  const minutes = ['00', '30'];

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto'>
      <div className='bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative'>
        <h3 className='text-xl font-bold mb-4 text-center'>Post Shift</h3>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <div>
              <select
                id='location'
                name='location'
                value={formData.location}
                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                className={`w-full px-4 py-2 rounded-lg border ${
                  errors.location ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-green-400`}
                required
              >
                <option value=''>Select Location</option>
                <option value='T1'>T1</option>
                <option value='T3'>T3</option>
                <option value='Island'>Island</option>
                <option value='Hamilton'>Hamilton</option>
              </select>
              {errors.location && <p className='text-red-500 text-sm mt-1'>{errors.location}</p>}
            </div>

            <div>
              <select
                id='checkpoint'
                name='checkpoint'
                value={formData.checkpoint}
                onChange={(e) => setFormData({ ...formData, checkpoint: e.target.value })}
                className={`w-full px-4 py-2 rounded-lg border ${
                  errors.checkpoint ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-green-400`}
                required
              >
                <option value=''>Select Checkpoint</option>
                <option value='International'>International</option>
                <option value='Domestic'>Domestic</option>
                <option value='TB'>TB</option>
                <option value='ITD/ITPC'>ITD/ITPC</option>
                <option value='NPS'>NPS</option>
                <option value='NPS-V'>NPS-V</option>
                <option value='HBS'>HBS</option>
                <option value='RSR'>RSR</option>
              </select>
              {errors.checkpoint && (
                <p className='text-red-500 text-sm mt-1'>{errors.checkpoint}</p>
              )}
            </div>

            <div>
              <label
                className='block text-gray-700 mb-1'
                htmlFor='startTime'
              >
                Start Time
              </label>
              <div className='flex space-x-2'>
                <select
                  id='startHour'
                  name='startHour'
                  value={formData.startHour}
                  onChange={(e) => setFormData({ ...formData, startHour: e.target.value })}
                  className={`w-1/2 px-4 py-2 rounded-lg border ${
                    errors.start ? 'border-red-500' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-green-400`}
                  required
                >
                  <option value=''>HH</option>
                  {hours.map((hour) => (
                    <option
                      key={hour}
                      value={hour}
                    >
                      {hour}
                    </option>
                  ))}
                </select>
                <select
                  id='startMinute'
                  name='startMinute'
                  value={formData.startMinute}
                  onChange={(e) => setFormData({ ...formData, startMinute: e.target.value })}
                  className={`w-1/2 px-4 py-2 rounded-lg border ${
                    errors.start ? 'border-red-500' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-green-400`}
                  required
                >
                  <option value=''>MM</option>
                  {minutes.map((minute) => (
                    <option
                      key={minute}
                      value={minute}
                    >
                      {minute}
                    </option>
                  ))}
                </select>
              </div>
              {errors.start && <p className='text-red-500 text-sm mt-1'>{errors.start}</p>}
            </div>

            <div>
              <label
                className='block text-gray-700 mb-1'
                htmlFor='endTime'
              >
                End Time
              </label>
              <div className='flex space-x-2'>
                <select
                  id='endHour'
                  name='endHour'
                  value={formData.endHour}
                  onChange={(e) => setFormData({ ...formData, endHour: e.target.value })}
                  className={`w-1/2 px-4 py-2 rounded-lg border ${
                    errors.end ? 'border-red-500' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-green-400`}
                  required
                >
                  <option value=''>HH</option>
                  {hours.map((hour) => (
                    <option
                      key={hour}
                      value={hour}
                    >
                      {hour}
                    </option>
                  ))}
                </select>
                <select
                  id='endMinute'
                  name='endMinute'
                  value={formData.endMinute}
                  onChange={(e) => setFormData({ ...formData, endMinute: e.target.value })}
                  className={`w-1/2 px-4 py-2 rounded-lg border ${
                    errors.end ? 'border-red-500' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-green-400`}
                  required
                >
                  <option value=''>MM</option>
                  {minutes.map((minute) => (
                    <option
                      key={minute}
                      value={minute}
                    >
                      {minute}
                    </option>
                  ))}
                </select>
              </div>
              {errors.end && <p className='text-red-500 text-sm mt-1'>{errors.end}</p>}
            </div>

            <div>
              <label
                className='block text-gray-700 mb-1'
                htmlFor='date'
              >
                Date
              </label>
              <input
                type='date'
                id='date'
                name='date'
                value={formData.date}
                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                className={`w-full px-4 py-2 rounded-lg border ${
                  errors.date ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-green-400`}
                required
              />
              {errors.date && <p className='text-red-500 text-sm mt-1'>{errors.date}</p>}
            </div>

            <div>
              <label
                className='block text-gray-700 mb-1'
                htmlFor='incentive'
              >
                Incentive (optional)
              </label>
              <input
                type='number'
                id='incentive'
                name='incentive'
                placeholder='Incentive amount'
                value={formData.incentive}
                min={0}
                max={999}
                onChange={(e) => setFormData({ ...formData, incentive: e.target.value })}
                className={`w-full px-4 py-2 rounded-lg border ${
                  errors.incentive ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-green-400`}
              />
              {errors.incentive && <p className='text-red-500 text-sm mt-1'>{errors.incentive}</p>}
            </div>

            <div className='col-span-2'>
              <label
                className='block text-gray-700 mb-1'
                htmlFor='note'
              >
                Note (max 140 characters)
              </label>
              <textarea
                id='note'
                name='note'
                placeholder='Additional information'
                value={formData.note}
                maxLength={140}
                onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                className={`w-full px-4 py-2 rounded-lg border ${
                  errors.note ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none focus:ring-2 focus:ring-green-400`}
              />
              {errors.note && <p className='text-red-500 text-sm mt-1'>{errors.note}</p>}
            </div>

            <div className='col-span-2'>
              <label className='flex items-center space-x-2'>
                <input
                  type='checkbox'
                  id='recurring'
                  name='recurring'
                  checked={formData.recurring}
                  onChange={(e) => setFormData({ ...formData, recurring: e.target.checked })}
                  className='form-checkbox h-5 w-5 text-green-600'
                />
                <span className='text-gray-700'>Recurring Shift</span>
              </label>
            </div>

            {formData.recurring && (
              <div className='col-span-2'>
                <label
                  className='block text-gray-700 mb-1'
                  htmlFor='endRecurringDate'
                >
                  End Date for Recurring Shift
                </label>
                <input
                  type='date'
                  id='endRecurringDate'
                  name='endRecurringDate'
                  value={formData.endRecurringDate}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      endRecurringDate: e.target.value,
                    })
                  }
                  className={`w-full px-4 py-2 rounded-lg border ${
                    errors.endRecurringDate ? 'border-red-500' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-green-400`}
                  required
                />
                {errors.endRecurringDate && (
                  <p className='text-red-500 text-sm mt-1'>{errors.endRecurringDate}</p>
                )}
              </div>
            )}

            <div className='col-span-2'>
              <label className='flex items-center space-x-2'>
                <input
                  type='checkbox'
                  id='isTrade'
                  name='isTrade'
                  checked={formData.isTrade}
                  onChange={(e) => setFormData({ ...formData, isTrade: e.target.checked })}
                  className='form-checkbox h-5 w-5 text-green-600'
                />
                <span className='text-gray-700'>Trade Shift</span>
              </label>
            </div>
          </div>

          <div className='flex justify-between mt-6'>
            <button
              type='submit'
              className='bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition'
            >
              Post Shift
            </button>
            <button
              type='button'
              onClick={onCancel}
              className='bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition'
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PostShiftForm;
