import { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from '../context/AuthContext';
import authService from '../services/authService';

function Login() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setIsLoggedIn } = useContext(AuthContext);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError('');
    let valid = true;

    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError('Please enter your password.');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (!valid) return;

    try {
      await authService.login({ email, password });
      setIsLoggedIn(true);
      toast.success('Logged in successfully!');
      navigate('/shifts');
    } catch {
      setError('Invalid email or password');
      toast.error('Login failed. Please check your credentials.');
      return;
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white p-8 rounded-lg shadow-lg w-full max-w-md'>
        <div className='text-center mb-4'>
          <img
            src='/gardashifts.png'
            alt='Garda Shifts Logo'
            className='mx-auto w-48 h-48'
          />
        </div>
        <h2 className='text-2xl font-bold mb-6 text-center'>Login</h2>
        <form onSubmit={handleLoginSubmit} className='space-y-4'>
          <input
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400'
          />
          {emailError && <p className='text-red-500'>{emailError}</p>}
          <input
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400'
          />
          {passwordError && <p className='text-red-500'>{passwordError}</p>}
          {error && <p className='text-red-500'>{error}</p>}
          <button
            type='submit'
            className='w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600'
          >
            Login
          </button>
        </form>
        <div className='mt-6 text-center'>
          <p className='text-sm mb-3'>Don't have an account?</p>
          <Link to='/register'>
            <button className='w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600'>
              Create Account
            </button>
          </Link>
          <p className='mt-4 text-sm'>
            <Link
              to='/forgot-password'
              className='text-blue-500 hover:underline'
            >
              Forgot Password?
            </Link>
          </p>
          <p className='mt-2 text-sm text-gray-500'>Created By Oliver - YYZ - T3 - East</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
