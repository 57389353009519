// File: src/App.js

import { OverlayProvider } from '@react-aria/overlays';
import { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import BottomNav from './components/BottomNav';
import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login';
import MyShifts from './components/MyShifts';
import News from './components/News';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import Settings from './components/Settings';
import Shifts from './components/Shifts';
import { AuthContext } from './context/AuthContext';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { isLoggedIn } = useContext(AuthContext);

  function PrivateRoute({ element }) {
    return isLoggedIn ? element : <Navigate to='/login' />;
  }

  return (
    <OverlayProvider>
      <Router>
        <div className='min-h-screen'>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/shifts' element={<PrivateRoute element={<Shifts />} />} />
            <Route path='/my-shifts' element={<PrivateRoute element={<MyShifts />} />} />
            <Route path='/news' element={<PrivateRoute element={<News />} />} />
            <Route path='/settings' element={<PrivateRoute element={<Settings />} />} />
            <Route path='/' element={<Navigate to='/login' />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
          </Routes>
          {isLoggedIn && <BottomNav />}
        </div>
      </Router>
    </OverlayProvider>
  );
}

export default App;
