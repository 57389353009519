import apiClient from './apiClient';

const API_URL = '/auth';

const register = async (userData) => {
  const response = await apiClient.post(`${API_URL}/register`, userData);
  return response.data;
};

const login = async (userData) => {
  const response = await apiClient.post(`${API_URL}/login`, userData);
  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
    window.dispatchEvent(new Event('login'));
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem('token');
  const logoutEvent = new Event('logout');
  window.dispatchEvent(logoutEvent);
};

const getToken = () => localStorage.getItem('token');

const forgotPassword = async (emailData) => {
  const response = await apiClient.post(`${API_URL}/forgot-password`, emailData);
  return response.data;
};

const resetPassword = async (passwordData) => {
  const response = await apiClient.post(`${API_URL}/reset-password`, passwordData);
  return response.data;
};

const getUserProfile = async () => {
  const response = await apiClient.get('/auth/profile');
  return response.data;
};

const updateUserProfile = async (userData) => {
  const response = await apiClient.put('/auth/profile', userData);
  return response.data;
};

const updateUserFCMToken = async (token) => {
  const response = await apiClient.post('/auth/fcm-token', { token });
  return response.data;
};

export default {
  forgotPassword,
  getToken,
  getUserProfile,
  login,
  logout,
  register,
  resetPassword,
  updateUserFCMToken,
  updateUserProfile,
};
